.container.contact__container{
    width: 60%;
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap:12%;
}

.contact__options{
display: flex;
flex-direction: column;
gap:1.2rem;

}

.contact__option {
    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 1.2rem;
    text-align: center;
    border: 1px solid transparent;
    transition: var(--transition);
}

.contact__option:hover {
    background: transparent;
    border-color: var(--color-primary-variant);

}

.contact__option a{
    margin-top: 0.7rem;
    display: inline-block;
    font-size: 0.8rem;
}

.contact__option i {
margin-bottom: 1rem;
}
.fa-envelope:hover {
    color: var(--color-primary);

}


form{
    display: flex;
    flex-direction: column;
    gap:1.2rem;
 margin-bottom: 9rem;

}

input, textarea {
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: transparent;
    border: 2px solid var(--color-primary);
    resize: none;
    color: var(--color-white);
}
.captcha{
    display: flex;
    align-items: center;
}

.refresh {
    padding: 0.2rem;
    border-radius: 25%;
    margin-left: 1rem;
    width: 1.5rem;
}



@media screen and (max-width:1024px) {
 .container.contact__container  {
    grid-template-columns: 1fr;
    gap:2rem;
 }
}

@media screen and (max-width:600px) {
    .container.contact__container  {
       width: var(--container-width-sm);
     }
}