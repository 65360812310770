.portfolio__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem;
}

.portfolio__item {
    background: var(--color-bg-variant);
    /* padding: 1.2rem;
border-radius: 2rem; */
    border: 1px solid transparent;
    transition: var(--transition);
}

.portfolio__item:hover {
    background-color: var(--color-primary-variant);
    background: transparent;
}

.portfolio__item-image {
   
    overflow: hidden;
    margin: auto;
}


.portfolio__item h3 {
    margin: 1rem 0 1rem;
}

.portfolio__item .btn {
    display: flex;
    gap: 1rem;
    margin: 1rem;
}

.buttons {
    display: flex;
    gap: 2rem;
}

.stack{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap:0.3rem;
    margin-bottom: 2rem;
}

@media screen and (max-width: 1024px) {
    .portfolio__container {
        grid-template-columns: 1fr;
    }
    .stack{
       
        grid-template-columns: 1fr 1fr;
     gap:0.5rem;
    }
}

    @media screen and (max-width: 600px) {
        .portfolio__container {
            grid-template-columns: 1fr;
            
        }

        .portfolio-item {
            max-width: 600px;
            min-width: 300px;
        }

       .portfolio__item-image img {
        width: 90%;
        height: auto;
       }
    }


@media screen and (max-width: 300px) {
#portfolio div{
    max-width: 90%;
}

.portfolio__item {
    max-width: 250;
}
article p{
    min-width: none;
    max-width: 250px;
}
.stack{
       
    grid-template-columns: 1fr;
    margin-left: 1.5rem;
 gap:0.5rem;
}
}