nav {
    background: rgba(0,0,0,0.3);
    width: max-content;
    min-width: 200px;
    display: block;
    padding: 0.7rem 1.7rem;
z-index: 2;
position: fixed;
left: 50%;
transform: translateX(-50%);
bottom: 2rem;
display: flex;
gap: 0.8rem;
border-radius: 3rem;
backdrop-filter: blur(15px);
}

nav a{
    background: transparent;
    height: 3rem;
    width: 3rem;
    padding: 0.9rem;
    border-radius: 50%;
    display: flex;
    color: var(--color-light);
    font-size: 1.1rem;
    text-align: center;

}
 nav i {
    margin: auto;
 }
nav a:hover{
    background: var(rgba(0,0,0, 0.3));
    color: var(--color-primary);
}

nav a.active {
    background: var(--color-bg);
    color: var(--color-primary);
}