.experience__container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 2rem;
}

.experience__container div {
    background: var(--color-bg-variant);
    padding: 2.4rem 5rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

.experience__container div:hover {
    background-color: transparent;
    border-color: var(--color-primary-variant);
    cursor: default;
}

.experience__container > div h3 {
    text-align: left;
    margin-bottom: 2rem;
    color: var(--color-primary);
}

li {
    line-height: 2.5rem;
}

li i {
    margin-right: 1rem;
    color: var(--color-primary);
}

@media screen and (max-width: 1024px) {
    .experience__container {
        grid-template-columns: 1fr 1fr;
    }

    .experience__container > div {
        width: 80%;
        padding: 2rem;
        margin: 0 auto;
    }

    .experience__skillset {
        padding: 1rem;
    }
}

@media screen and (max-width: 600px) {
    .experience__container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }

    .experience__container > div {
        padding: 3rem 4rem;
    }
}
@media screen and (max-width: 300px) {
    .experience__container > div {
        padding: 1rem 2rem;
    }
}