@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");

#root {
    overflow:hidden;
}

html{
    margin: auto;
    min-width: 200px;
}

body {
    padding-top: 1rem;
    /* Created with https://www.css-gradient.com */
    background: #010101;
    background: var(--color-bg);

    color: white;
    font-family: "Poppins", sans-serif;
}



* {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}

:root {
    --color-bg: #1f1f38;
    --color-bg-variant: #2c2c6c;
    --color-primary: #3bbdf0;
    --color-primary-variant: rgbs(77, 181, 255, 0.4);
    --color-white: #fff;
    --color-light: rgba(255, 255, 255, 0.6);

    --container-width-lg: 75%;
    --container-width-md: 86%;
    --container-width-sm: 90%;

    --transition: all 400ms ease;
}

html {
    scroll-behavior: smooth;
    background: var(color-bg);
    color: var(color-white);
    line-height: 1.7;
}
section > p {
    text-align: center;
}

.container {
    width: var(--container-width-md);
    margin: 0 auto;
}

h1,
h2,
h3,
h4,
h5 {
    font-weight: 500;
}

h1 {
    font-size: 2.5rem;
}

section {
    margin-top: 8rem;
    padding-top: 1rem;
}

section > h2,
section > h5 {
    text-align: center;
    color: var(--color-light);
}

section > h2 {
    color: var(--color-primary);
    margin-bottom: 3rem;
}

.text-light {
    color: var(--color-light);
}

a {
    color: var(--color-primary);
    transition: var(--transition);
}

a:hover {
    color: var(--color-white);
}

.btn {
    width: max-content;
    display: inline-block;
    color: var(--color-primary);
    padding: 0.75rem 1.2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid var(--color-primary);
    transition: var(--transition);
}

.btn:hover {
    background: var(--color-white);
    color: var(--color-bg);
    border-color: white;
}

.btn-primary {
    background: var(--color-primary);
    color: var(--color-bg);
}

/* img {
    display: block;
    width: 100%;
    object-fit: cover;
} */

@media screen and (max-width: 1024px) {
    .container  {
        width: var(--container-width-md);
    }
    section {
        margin-top: 6rem;
    }
}

@media screen and (max-width: 600px) {
    .container {
        width: var(--container-width-sm);
    }
    section > h2 {
        margin-bottom: 2rem;
    }
}
