.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#language {
  padding-right: 4.5rem;
  text-align: right;
}

#language button{
padding: 0.3rem;
}

.language a{
  color:var(--color-bg)

}

.footer{
 display: flex;
 justify-content: right;
 align-items: flex-start;
 margin-bottom: 1rem;
 margin-right: 1rem;
}